.Info {
    font-size: 13px;
    margin: 10px 15px 10px 5px;
    padding: 20px;
    background-color: #bcebff;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
}

.Info-logo {
  position: absolute;
  margin: 5px 0px;
}

.Info-logo > i {
  font-size: 25px;
}

.Info-content {
  margin-left: 30px;
  position: relative;
  left: 15px;
}