
.RegistratioAnswer-question {
    background-color: rgba(255,255,255,.8);
    border: solid 1px lightgrey;
    border-radius: 10px;
    margin: 5px 0px;
    padding: 20px 20px 10px 20px;
}

.RegistratioAnswer-answer {
    border: solid 1px lightgrey;
    border-radius: 10px;
    margin: 5px 0px;
    padding: 10px 20px 20px 20px;
    height:  calc(100% - 20px);
}

.RegistratioAnswer-answer-red {
    border: solid 1px red;
}

.RegistratioAnswer-answer-orange {
    border: solid 1px orange;
}

.RegistratioAnswer-answer legend {
    font-size: 13px;
    padding-left: 3px;
}

.RegistratioAnswer-answer .Message-text {
    color: #BBB !important;
    font-size: 12px;
}

.RegistratioAnswer-edit .RegistratioAnswer-edit-button {
    position: absolute;
    top: 5px;
    right: 40px;
}