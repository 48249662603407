
.Registration {
    width: 100%;
    position: relative;
    font-size: 15px;
    height: 24px;
    overflow: visible;
    margin: 2px 0px;
    padding: 3px 5px 3px 7px;
    line-height: 1.2;
    animation: halfFixedPopEffect 0.5s ease;
    -moz-animation: halfFixedPopEffect 0.5s ease;
    -webkit-animation: halfFixedPopEffect 0.5s ease;
    -o-animation: halfFixedPopEffect 0.5s ease;
    cursor: pointer;
}

.Registration:hover {
    background-color: rgba(0, 159, 227, 0.05);
}

.Registration-name {
    display: inline-block;
    width: calc(100% - 14px);
    padding-left: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    color: #009fe3;
}

.Registration i {
    display:inline;
    font-size: 14px;
    line-height: 1.2;
    color: #009fe3;
}