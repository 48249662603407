@font-face { font-family: "Fjalla One"; src: url('./font/FjallaOne-Regular.ttf'); }

:root {
    --default-color: grey;
    --default-light-color: lightgrey;
    --primary-color: #009fe3;
    --light-primary-color: #bcebff;
    --hovered-primary-color: #8fddff;
    --secondary-color: #e40613;
    --light-secondary-color: #fed7da;
    --hovered-secondary-color: #ffa8b0;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: var(--default-color);
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

#App {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    background: linear-gradient(110deg, #EEE 60%, #E9E9E9 60%);
}

h1 {
	color: #010101;
    text-transform: uppercase;
    border-left: 4px solid #000;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 0px 10px;
    margin: 25px 0px 15px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left !important;
}

h2 {
	color: #010101;
    border-left: 4px solid grey;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 0px 10px;
    margin: 25px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left !important;
}

h3 {
	color: #010101;
    border-left: 4px solid lightgrey;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 0px 10px;
    margin: 10px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    text-align: left !important;
}

h4 {
	color: #010101;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 0px 10px;
    margin: 10px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    text-align: left !important;
}

a {
	color: #009fe3;
}

/* COLORS */

.blue-font {
	color: #009fe3 !important;
}

.orange-font {
	color: #f0622f !important;
}

.red-font {
	color: #e40613 !important;
}

.red-background {
	background-color: #e40613 !important;
}

.grey-background {
	background-color: grey !important;
}

/* PAGE */

.page {
	padding: 10px 20px;
}

.row-spaced {
	padding-bottom: 40px ;
}

.full-page {
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
}

.full-size {
	height: 100%;
	width: 100%;
}

.max-sized-page {
	max-width: 1200px;
	margin: auto;
}

/* BUTTON */

.bottom-right-buttons {
	position: absolute;
	bottom: 30px;
	right: 40px;
}

.bottom-right-buttons > button {
	margin-left: 5px;
}

.bottom-left-buttons {
	position: absolute;
	bottom: 30px;
	left: 40px;
}

.bottom-left-buttons > button {
	margin-left: 5px;
}

.top-right-buttons {
	position: absolute;
	top: 30px;
	right: 20px;
}

.top-right-buttons > button {
	margin-left: 5px;
}

.right-buttons > button {
	margin: 7px 2px;
	float: right;
}

.centered-buttons {
	text-align: center;
}

button {
	display:inline-block;
	padding:0.3em 1.2em;
	margin:0 0.1em 0.1em 0;
	border:0.16em solid rgba(0,0,0,0);
	border-radius:2em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:300;
	color:#FFFFFF;
	text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
	text-align:center;
	transition: all 0.2s;
	background-color: #009fe3;
	opacity: .5;
}

button.small-button {
	padding: 0.1em 0.4em !important;
	font-size: 12px;
}

button:hover {
	border-color: rgba(0,0,0,1);
	opacity: 1;
}

button:disabled {
	background-color: grey !important;
	cursor: default !important;
}

select:disabled {
	background-color: rgba(239, 239, 239, 0.3) !important;
	cursor: default !important;
}

@media all and (max-width:30em){
	button {
		display:block;
		margin:0.2em auto;
	}
}

.blue-button {
	background-color: #009fe3;
}

.red-button {
	background-color: #e40613;
}

.link-button {
	background-color: transparent;
	color: #009fe3;
	text-shadow: none;
	transition: none;
}

.link-button:hover {
	border-color: transparent;
}

.link-button:disabled {
	background-color: transparent;
	cursor: default;
	color: lightgrey;
}

/* INPUT */

input, textarea, select {
  position: relative;
}

input, textarea, select {
  display: block;
  margin: 0;
  padding: 5px 12px;
  color: inherit;
  width: 100%;
  font-family: inherit;
  font-size: var(--inputFontSize);
  font-weight: inherit;
  line-height: var(--inputLineHeight);
  border: lightgrey 2px solid;
  border-radius: 0.4rem;
  transition: box-shadow var(--transitionDuration);
  height: 40px;
}

input::placeholder, textarea::placeholder, select::placeholder {
  	color: #B0BEC5;
}

input:focus, textarea:focus, select:focus {
	border: 1px solid #009fe3;
}

textarea {
	height: 100px;
}

.medium-editor-element {
	display: block;
	margin: 0;
	padding: 5px 12px !important;
	color: inherit;
	width: 100%;
	font-family: inherit;
	font-size: var(--inputFontSize);
	font-weight: inherit;
	line-height: var(--inputLineHeight);
	border: lightgrey 2px solid;
	border-radius: 0.4rem;
	transition: box-shadow var(--transitionDuration);
	background-color: white;
	min-height: 40px !important;
	border-radius: 4px;
}

.medium-editor-toolbar button {
	padding: 3px !important;
	background-color: black;
	opacity: 1;
	min-width: 30px;
}

.centered {
	text-align: center;
}

.centered-middled {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}


/* POPUP */

.Popup-full-size-content {
	height: 95%;
	width: 80% !important;
	max-width: 1200px;
	padding: 10px;
	animation: fadeIn ease .5s;
	-webkit-animation: fadeIn ease .5s;
	-moz-animation: fadeIn ease .5s;
	-o-animation: fadeIn ease .5s;
	-ms-animation: fadeIn ease .5s;
}

.Popup-small-size-content {
	width: 90% !important;
	max-height: 90% !important;
	max-width: 900px;
	padding: 10px;
	animation: fadeIn ease .5s;
	-webkit-animation: fadeIn ease .5s;
	-moz-animation: fadeIn ease .5s;
	-o-animation: fadeIn ease .5s;
	-ms-animation: fadeIn ease .5s;
}

.popup-content {
	padding: 10px 20px 30px 20px !important;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #EEE !important;
	text-align: initial;
	white-space: initial;
}

/* ANIMATION */

.fade-in {
	animation: fadeIn ease 2s forwards;
	-webkit-animation: fadeIn ease 2s forwards;
	-moz-animation: fadeIn ease 2s forwards;
	-o-animation: fadeIn ease 2s forwards;
	-ms-animation: fadeIn ease 2s forwards;
}

@keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-moz-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-webkit-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-o-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-ms-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}

.resize-animation {
	-webkit-transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
	-moz-transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
	-o-transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
	transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
}

/* NOTIFICATION */

.notification-info {
  background-color: #009fe3 !important;
}

.notification-success {
  background-color: #51a351 !important;
}

.notification-warning {
  background-color: #f89406 !important;
}

.notification-error {
  background-color: #e40613 !important;
}

.notification-container {
	pointer-events: none;
	opacity: 0.7;
}

/* BADGE */

.Badge {
	position: absolute;
	bottom: -5px;
	right: -3px;
	color: white;
	background-color: #e40613;
	border:solid 1px #e40613;
	border-radius: 25px;
	padding: 0px 7px;
}

/* DOT */

.dot {
	margin: 1px 4px;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

/* DRAG'n'DROP */

.Droppable-bar {
	width: 100%;
	min-height: 50px;
	padding: 5px 10px;
	margin: 2px 0px;
	background-color: #bcebff;
    border-radius: 0.4rem;
}

.Droppable-bar-unassigned {
	background-color: lightgrey !important;
}

.Droppable-element {
	display: inline-block;
	padding: 5px 10px;
	margin: 2px 2px;
	background-color: #8fddff;
    border-radius: 0.4rem;
}

.Droppable-element-block {
	display: block !important;
}

/* TREE */

.rd3t-tree-container {
	height: calc(100% - 25px) !important;
	border: lightgrey 2px solid;
    border-radius: 0.4rem;
    background-color: white;
   	margin: 15px 0px 10px 0px;
}

/* OTHER */

.content-media {
    text-align: center;
}

.content-media img {
    max-width: 100%;
}

.centered-or {
    width: 100%;
    font-style: italic;
    text-align: center;
    color: grey;
    padding: 20px 0px;
}